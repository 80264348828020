// src/data/equipmentData.js

const equipmentData = [
  { id: 1, name: "", image: "/images/_DSC0120.JPG" },
  { id: 2, name: "ICU Ventilator", image: "/images/icu ventilator.jpeg" },
  // { id: 3, name: "laryngoscope", image: "/images/IMG-20241125-WA0050.jpg" },
  { id: 4, name: "Thermostat Oven", image: "/images/Thermostat Oven.jpg" },
  { id: 5, name: "Binocular Microscope", image: "/images/microscope2.jpg" },
  { id: 6, name: "Patient Monitor", image: "/images/IMG-20241206-WA0019.jpg" },
  { id: 7, name: " Single Bottle Suction Machine", image: "/images/IMG-20241206-WA0020.jpg" },
  { id: 8, name: "Hematology Analyzer", image: "/images/IMG-20241206-WA0021.jpg" },
  { id: 9, name: "IMG-20241206-WA0022", image: "/images/IMG-20241206-WA0022.jpg" },
  { id: 10, name: "Centrifuge", image: "/images/IMG-20241206-WA0023.jpg" },
  { id: 11, name: "Oxygen Concentrator", image: "/images/IMG-20241206-WA0024.jpg" },
  { id: 12, name: "Doctor stool", image: "/images/IMG-20241206-WA0025.jpg" },
  { id: 13, name: "Lympha-mat 300", image: "/images/IMG-20241206-WA0026.jpg" },
  { id: 14, name: "Medical-Trolley", image: "/images/IMG-20241206-WA0027.jpg" },
  { id: 15, name: "Patient-Trolley", image: "/images/IMG-20241206-WA0028.jpg" },
  { id: 16, name: "Walking Aid", image: "/images/IMG-20241206-WA0029.jpg" },
  // { id: 17, name: "IMG-20241206-WA0030", image: "/images/IMG-20241206-WA0030.jpg" },
  { id: 18, name: "Drip stand, First aid box, Crutches, Operating table...", image: "/images/IMG-20241206-WA0031.jpg" },
  { id: 19, name: "Sutures", image: "/images/IMG-20241125-WA0032.jpg" },
  { id: 20, name: "Digital BP Apparatus, Glucometer", image: "/images/IMG-20241125-WA0033.jpg" },
  // { id: 21, name: "IMG-20241125-WA0034", image: "/images/IMG-20241125-WA0034.jpg" },
  // { id: 22, name: "IMG-20241125-WA0035", image: "/images/IMG-20241125-WA0035.jpg" },
  // { id: 23, name: "IMG-20241125-WA0036", image: "/images/IMG-20241125-WA0036.jpg" },
  // { id: 24, name: "IMG-20241125-WA0037", image: "/images/IMG-20241125-WA0037.jpg" },
  // { id: 25, name: "IMG-20241125-WA0038", image: "/images/IMG-20241125-WA0038.jpg" },
  // { id: 26, name: "IMG-20241125-WA0039", image: "/images/IMG-20241125-WA0039.jpg" },
  { id: 27, name: "medical xray film", image: "/images/IMG-20241125-WA0040.jpg" },
  // { id: 28, name: "IMG-20241125-WA0041", image: "/images/IMG-20241125-WA0041.jpg" },
  { id: 29, name: "Infant Incubator", image: "/images/IMG-20241125-WA0042.jpg" },
  // { id: 30, name: "IMG-20241125-WA0043", image: "/images/IMG-20241125-WA0043.jpg" },
  // { id: 31, name: "IMG-20241125-WA0044", image: "/images/IMG-20241125-WA0044.jpg" },
  // { id: 32, name: "IMG-20241125-WA0045", image: "/images/IMG-20241125-WA0045.jpg" },
  { id: 33, name: "Laboratory Consumable", image: "/images/IMG-20241125-WA0046.jpg" },
  { id: 34, name: "IMG-20241125-WA0047", image: "/images/IMG-20241125-WA0047.jpg" },
  // { id: 35, name: "IMG-20241125-WA0048", image: "/images/IMG-20241125-WA0048.jpg" },
  { id: 36, name: "Suction Apparatus", image: "/images/IMG-20241125-WA0049.jpg" },
  { id: 37, name: "Surgical Suction Machine", image: "/images/Surgical Suction Machine.jpg" },
  { id: 38, name: "Shelf Display", image: "/images/IMG-20241125-WA0037.jpg" },
  { id: 39, name: "Cataract Surgery Instrument", image: "/images/Cataract Surgery Instrument.jpeg" },
  { id: 40, name: "Theater Lights", image: "/images/Theater Lights.jpeg" },
  { id: 41, name: "Ultrasound Scanning Machine", image: "/images/Ultrasound scanning machine.jpeg" },
  // { id: 42, name: "Shelf Display", image: "/images/IMG-20241125-WA0038.jpg" },
  { id: 43, name: "Medical Storage Crash Cart", image: "/images/Rolling Medical Storage Crash Cart with Drawers.jpg" },
  { id: 44, name: "Electric and Manual Wheelchair", image: "/images/Electric and Manual Wheelchair .jpeg" },
  { id: 45, name: "Laboratory Consumables", image: "/images/Laboratory Consumables.jpeg" },
  { id: 46, name: "Surgical Bed", image: "/images/Surgical bed__.jpeg" },
  { id: 47, name: "Stainless Steel Manual Folding Wheelchair", image: "/images/Stainless Steel Manual Folding Wheelchair.jpeg" },
  { id: 48, name: "Medical Electric Weighing Scales", image: "/images/Medical Electric Hospital Weighing Scales 150kg Weight 70~190cm.jpeg" },
  { id: 49, name: "Non Woven Surgical Disposable Products", image: "/images/Non Woven Surgical Disposable Products.jpeg" },
  // { id: 50, name: "_DSC0100", image: "/images/_DSC0100.JPG" },
  { id: 51, name: "Laboratory consumables", image: "/images/_DSC0107.JPG" },
  { id: 52, name: "Surgical Consumables ", image: "/images/_DSC0150.JPG" },
  // { id: 53, name: "_DSC0151", image: "/images/_DSC0151.JPG" },
  { id: 54, name: "Forceps", image: "/images/_DSC0157.JPG" },
  { id: 55, name: "Walking aids & wheelchairs", image: "/images/_DSC0160.JPG" },
  { id: 56, name: "Patient-Trolley", image: "/images/_DSC0161.JPG" },
  { id: 57, name: "Scanning Machine", image: "/images/_DSC0162.JPG" },
  { id: 58, name: "Kidney Dish & Gallipot", image: "/images/Kidney Dish & Galli Pot.JPG" },
  { id: 59, name: "Operating Table", image: "/images/Operating Table.JPG" },
  { id: 60, name: "Infrared Lamp", image: "/images/Infrared Lamp.JPG" },
  { id: 61, name: "Laryngoscope", image: "/images/Laryngoscope.JPG" },
  { id: 62, name: "OTOSCOPE", image: "/images/OTOSCOPE.JPG" },
  { id: 63, name: "PULSE OXIMETER", image: "/images/PULSE OXIMETER.JPG" },
  { id: 64, name: "Haematocrit Centrifuge", image: "/images/Haematocrit centrifuges.JPG" },
  { id: 65, name: "Moisture Analyzer", image: "/images/Moisture Analyzer.JPG" },
  { id: 66, name: "Medical sterilizer", image: "/images/Medical sterilizer.JPG" },
];

export default equipmentData;
